<template>
	<div>

		<search
			v-if="program.search"
			:program="program"
			:search="item_search"
			:option="item_search_option"
			@toExcel="toExcel"

			@click="getSearch"
		>

		</search>

		<ul class="hide-pc mt-10">
			<li
				v-for="(item, index) in point_list"
				:key="'item_' + index"
				class="li-point-list under-line box-shadow box pa-10 mt-20"
			>
				<div>
					<div>사용 일시</div>
					<div>{{ item.wDate }}</div>
				</div>
				<div>
					<div>구분</div>
					<div>{{ item.use_div_name }}</div>
				</div>
				<div>
					<div>사용 포인트</div>
					<div :class="'color-' + item.use_type_color">{{  item.use_point | makeComma}} P</div>
				</div>
				<div>
					<div>잔여 포인트</div>
					<div class="font-weight-bold">{{ item.point_now | makeComma }}P</div>
				</div>
				<div
					v-if="item.charge_id"
				>
					<div>결제 수단</div>
					<div>{{ item.charge_type_name }}</div>
				</div>
				<div
					v-if="item.charge_id"
				>
					<div>충전 금액</div>
					<div>
						<template v-if="item.charge_id">{{ item.charge_price | makeComma }}원</template>
						<template v-else>{{ item.charge_price}}</template>
					</div>
				</div>
			</li>
		</ul>
		<table
			class="table hide-m"
		>
			<colgroup>
				<col width="60px"/>
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />

				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
			</colgroup>
			<thead>
			<tr>
				<th>No</th>
				<th>일시</th>
				<th>구분</th>
				<th>내용</th>

				<th>결제 수단</th>
				<th>결제 금액</th>
				<th>포인트</th>
				<th>잔여 포인트</th>
			</tr>
			</thead>
			<tbody>
			<tr
				v-for="(item, index) in point_list"
				:key="'item_' + index"
			>
				<td>{{  item.no }}</td>
				<td>{{ item.wDate }}</td>
				<td :class="'color-' + item.use_type_color">{{ item.use_type_name }}</td>
				<td>{{ item.use_div_name }}</td>

				<td>{{ item.charge_type_name }}</td>
				<td>
					<template v-if="item.charge_id">{{ item.charge_price | makeComma }}원</template>
					<template v-else>{{ item.charge_price}}</template>
				</td>
				<td :class="'color-' + item.use_type_color">{{  item.use_point | makeComma}} P</td>
				<td>{{ item.point_now | makeComma }}P</td>
			</tr>
			</tbody>
		</table>

		<Pagination
			:program="program"
			:align="'center'"
			:options="item_search"

			@click="getData"

			class="mt-auto pa-10"
		></Pagination>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@close="is_excel = false"
		></Excel>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "../../components/Excel";
export default {
	name: 'PointHistory'
	, components: {Excel, Search, Pagination}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '포인트 내역'
				, title: true
				, bottom: true
				, top_line: true
				, search: true
			}
			, items_point: []
			, item_search: {
				page: 1
				, list_cnt: 10
				, cnt: 0
				, tCnt: 0
				, sDate: this.$date.getLastDate(7,'-')
				, eDate: this.$date.getToday('-')
				, use_type: ''
				, use_div: ''
			}
			, item_search_option: {
				sDate: true
				, eDate: true
				, is_keyword: true
				, is_cnt: true
				, select: [
					{ name: '적립 구분', column: 'use_type', items: [
							{ name: '적립', column: '+'}
							,{ name: '차감', column: '-'}
						]
					}
					, { name: '포인트 구분', column: 'use_div', items: [
							{ name: '포인트 충전', column: 'charge'}
							,{ name: '포인트 결제', column: 'order'}
							,{ name: '포인트 선물', column: 'present'}
							,{ name: '관리자 지급', column: 'admin'}
							,{ name: '충전 결제 취소', column: 'cancel'}
							,{ name: '주문 결제 취소', column: 'o_cancel'}
							,{ name: '모바일 상품권 취소', column: 'm_cancel'}
							,{ name: '모바일 상품권 환급', column: 'm_return'}
						]
					}
				]
				, is_excel: true
			}
			, is_excel: false
			, excel_data: {
				name: '포인트 내역'
				,header: [
					{ key: 0, name: '일시', column: 'wDate'}
					,{ key: 0, name: '구분', column: 'use_type_name'}
					,{ key: 0, name: '내용', column: 'use_div_name'}
					,{ key: 0, name: '결제 수단', column: 'charge_type_name'}
					,{ key: 0, name: '결제 금액', column: 'charge_price'}
					,{ key: 0, name: '포인트', column: 'use_point'}
					,{ key: 0, name: '잔여포인트', column: 'point_now'}
				]
				,content: null
			}
		}
	}

	, computed:{
		point_list: function(){
			return this.items_point.filter( (item, index) => {

				item.no = this.item_search.tCnt - (this.item_search.page * this.item_search.list_cnt - this.item_search.list_cnt) - index
				return item
			})
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'member/getPointList'
					, data: this.item_search
				})

				if(result.success){
					this.items_point = result.data.result
					this.item_search.tCnt = result.data.tCnt
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(){
			this.item_search.page = 1

			this.getData()
		}
		, toExcel: function(){
			this.is_excel = true
			this.excel_data.content = this.items_point
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>

<style>
.li-point-list {

}

.li-point-list > div{
	display: flex; justify-content: space-between; align-items: center;
}
.li-point-list > div:not(:first-child){
	margin-top: 10px;
}
</style>